import { isDefined } from "@clipboard-health/util-ts";
import { get, useGetQuery } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { SHIFT_SLOTS_V2_PAGE_SIZE } from "@src/appV2/OpenShifts/constants";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { useMemo } from "react";
import { z } from "zod";

import {
  type WorkerPublicProfile,
  workerPublicProfileResponseSchema,
} from "../../WorkWithFriends/hooks/useGetWorkerPublicProfile";
import { useIsWorkWithFriendsV2Enabled } from "../../WorkWithFriends/hooks/useIsWorkWithFriendsV2Enabled";

export const GET_SHIFT_SLOTS_PATH = "/v2/shift-slots";
export const GET_SHIFT_SLOTS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${GET_SHIFT_SLOTS_PATH}`;

const shiftSlotSchema = z.object({
  type: z.literal("shift-slot"),
  id: z.string(),
  attributes: z.object({
    workerIds: z.array(z.string()),
    shiftAssignments: z.array(
      z.object({
        workerId: z.string(),
        workingAgentReq: z.string(),
      })
    ),
    availableQualifications: z.record(z.string(), z.number()),
  }),
});
export const shiftSlotResponseSchema = z.object({
  data: z.array(shiftSlotSchema),
  included: z.array(workerPublicProfileResponseSchema),
});

export type ShiftSlotResponse = z.infer<typeof shiftSlotResponseSchema>;

export interface ShiftAssignment {
  workerId: string;
  workingAgentReq: string;
  worker: WorkerPublicProfile;
}

export interface ShiftSlotCoalesced {
  shiftAssignments: ShiftAssignment[];
  availableQualifications: Record<string, number>;
}

interface ShiftSlotsParams {
  shiftIds: string[];
}

/*
 * This endpoint fetches data regarding "overlapping shifts" for any given list of shift IDs.
 * These overlapping shifts are also known as "Shift slots".
 * A "Shift slot" is defined as shifts that have the same start and end times, who are by the same facility.
 *
 * Think "Working night shift" in a warehouse. Even though our data model marks each shift as separate for each agent,
 * our users' mental model is that they're all the same.
 *
 * Technically the model below is non-plural (i.e. singular) in "shift slot", but packed in it is the information regarding
 * All the shifts that are overlapping with each other.
 *
 * All overlapping shifts that aren't booked, are represented as a number under "availableQualifications".
 * All overlapping shifts that are booked, are represented as a worker ID under "workerIds".
 */
export function useGetShiftSlotsV2(props: ShiftSlotsParams) {
  const { shiftIds } = props;

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();

  const shiftSlotsQueryResult = useGetQuery({
    url: GET_SHIFT_SLOTS_URL,
    queryParams: { shiftIds: shiftIds.join(",") },
    responseSchema: shiftSlotResponseSchema,
    meta: {
      logErrorMessage: `${APP_V2_APP_EVENTS.GET_SHIFT_SLOTS_FAILURE} ${shiftIds.join(", ")}`,
    },
    enabled: shiftIds.length > 0 && isWorkWithFriendsV2Enabled,
  });

  return useMemo(() => {
    if (!shiftSlotsQueryResult.data) {
      return {};
    }

    return mapShiftSlotsToShiftSlotsById(shiftSlotsQueryResult.data);
  }, [shiftSlotsQueryResult.data]);
}

export function useGetPaginatedShiftSlotsV2(
  params: ShiftSlotsParams,
  options: UseInfiniteQueryOptions<ShiftSlotResponse, AxiosError> = {}
) {
  const { shiftIds } = params;

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();

  const queryResult = useInfiniteQuery({
    queryKey: [GET_SHIFT_SLOTS_URL, params],
    queryFn: async ({ pageParam: pageCount = 0 }: QueryFunctionContext<QueryKey, number>) => {
      const response = await get({
        url: GET_SHIFT_SLOTS_URL,
        queryParams: {
          shiftIds: shiftIds
            .slice(SHIFT_SLOTS_V2_PAGE_SIZE * pageCount, SHIFT_SLOTS_V2_PAGE_SIZE * (pageCount + 1))
            .join(","),
        },
        responseSchema: shiftSlotResponseSchema,
      });
      return response.data;
    },
    getNextPageParam: (_lastPage, allPages) => {
      if (shiftIds.length > allPages.length * SHIFT_SLOTS_V2_PAGE_SIZE) {
        return allPages.length;
      }

      return undefined;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PAGINATED_BOOKABILITY_STATUS_FAILURE,
    },
    ...options,
    enabled: shiftIds.length > 0 && isWorkWithFriendsV2Enabled,
  });

  const { data: paginatedShiftSlots } = queryResult;

  const shiftSlotsById = useMemo(() => {
    const combinedData = paginatedShiftSlots?.pages.reduce(
      (accumulator, page) => ({
        data: [...accumulator.data, ...page.data],
        included: [...accumulator.included, ...page.included],
      }),
      { data: [], included: [] }
    );
    return mapShiftSlotsToShiftSlotsById(combinedData);
  }, [paginatedShiftSlots?.pages]);

  if (!isDefined(paginatedShiftSlots)) {
    return {
      shiftSlotsById: {} as const,
      queryResult,
    };
  }

  return {
    shiftSlotsById,
    shiftSlotsLoadedShiftsCount: paginatedShiftSlots.pages.length * SHIFT_SLOTS_V2_PAGE_SIZE,
    queryResult,
  };
}

function mapShiftSlotsToShiftSlotsById(
  shiftSlots?: ShiftSlotResponse
): Record<string, ShiftSlotCoalesced> {
  if (!shiftSlots?.data) {
    return {};
  }

  const workersById = shiftSlots.included.reduce<Record<string, WorkerPublicProfile>>(
    (accumulator, worker) => {
      accumulator[worker.id] = worker;
      return accumulator;
    },
    {}
  );

  return shiftSlots.data.reduce<Record<string, ShiftSlotCoalesced>>((accumulator, shiftSlot) => {
    accumulator[shiftSlot.id] = {
      availableQualifications: shiftSlot.attributes.availableQualifications,
      shiftAssignments: shiftSlot.attributes.shiftAssignments.map(
        ({ workerId, workingAgentReq }) => ({
          workerId,
          workingAgentReq,
          worker: workersById[workerId],
        })
      ),
    };
    return accumulator;
  }, {});
}

interface ConvertShiftToSlotIdParams {
  start: string;
  end: string;
  facilityId: string;
}
export function convertShiftToSlotId(params: ConvertShiftToSlotIdParams) {
  const { start, end, facilityId } = params;
  return `${facilityId}-${start}-${end}`;
}
