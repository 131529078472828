import { parseISO } from "date-fns";
import { forwardRef } from "react";
import type { ItemProps, ListRange, VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";

import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { ShiftListDateHeader } from "./ListDateHeader";
import { ShiftListFooter } from "./ListFooter";
import { ShiftListItemWrapper } from "./ListItemWrapper";
import { ShiftListStickyHeader } from "./ListStickyHeader";
import type { OpenShiftListItem } from "./types";
import type { VirtualShiftListItem } from "./useVirtualShiftListContext/types";

interface ShiftVirtualizedListProps {
  items: VirtualShiftListItem[];
  renderItem: (item: OpenShiftListItem) => React.ReactNode;
  scrollRef?: React.RefObject<HTMLDivElement>;
  onEndReached?: () => void;
  isLoadingMore?: boolean;
  onRangeChanged?: (range: ListRange) => void;
}

function BaseShiftVirtualizedList(
  props: ShiftVirtualizedListProps,
  ref: React.Ref<VirtuosoHandle>
) {
  const { items, scrollRef, onEndReached, renderItem, isLoadingMore, onRangeChanged } = props;

  return (
    <Virtuoso
      ref={ref}
      data={items}
      customScrollParent={scrollRef?.current ?? undefined}
      width="100%"
      increaseViewportBy={300}
      topItemCount={0}
      endReached={onEndReached}
      components={{
        Item: (props: ItemProps<VirtualShiftListItem>) => {
          const { item, ...rest } = props;
          if (item.type === "group-header") {
            return <ShiftListStickyHeader {...rest} scrollParentRef={scrollRef} />;
          }

          return <ShiftListItemWrapper {...rest} />;
        },
        Footer: () => <ShiftListFooter isLoadingMore={isLoadingMore} />,
      }}
      itemContent={(_, item) => {
        if (item.type === "group-header") {
          const date = parseISO(item.dateIso);
          const label = formatDayOfMonthWithDayName(date);
          return (
            <ShiftListDateHeader
              key={item.dateIso}
              label={label}
              totalShiftsCount={item.totalShiftsCount}
            />
          );
        }

        return renderItem(item);
      }}
      rangeChanged={onRangeChanged}
    />
  );
}

export const ShiftVirtualizedList = forwardRef(BaseShiftVirtualizedList);
